import { useState } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

import Style from "./style.module.css";
import { WEB_URI } from "../services/helper";

function Navbar() {
  const [toggleBar, settoggleBar] = useState(false);

  return (
    <nav className="flex flex-row  h-full justify-around items-center md:py-2  md:justify-normal px-2 md:px-24 text-inherit bg-inherit ">
      <div className="w-full  md:w-2/12 flex items-center justify-between md:mx-0">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ez-the-one-app.appspot.com/o/NavLogo%20(1).png?alt=media&token=55d5a44c-23a2-4903-88b3-1769d2bf075a"
          rel="preload"
          alt="pic"
          className="w-[100px]  h-12  object-contain"
        />
        <span
          className="flex md:hidden md:opacity-0 mr-1 z-50 ease-in-out"
          onClick={() => settoggleBar(!toggleBar)}
        >
          {toggleBar ? (
            <AiOutlineClose size={30} />
          ) : (
            <CiMenuBurger size={30} />
          )}
        </span>
      </div>
      <div className="w-6/12 h-2/3 hidden md:flex flex-row   gap-8 items-center ">
        <NavLink
          to={WEB_URI}
          className={` h-full flex items-center justify-center relative group`}
        >
          <span className={`  `}>Home</span>
        </NavLink>

        <NavLink
          to={WEB_URI}
          className={` h-full flex items-center justify-center relative group`}
        >
          <span className={` ${Style.navNames} `}>Explore</span>
        </NavLink>
        <NavLink
          to={`${WEB_URI}/brands`}
          className={` h-full flex items-center justify-center relative group `}
        >
          <span className={` ${Style.navNames}  `}>For Brands</span>
        </NavLink>
        <NavLink
          to={`${WEB_URI}/creators`}
          className={` h-full flex items-center justify-center relative group `}
        >
          <span className={` ${Style.navNames}  `}>For Creators</span>
        </NavLink>
      </div>
      <div className="w-4/12 hidden md:flex flex-row justify-end items-center">
        <div className="mr-4 flex items-center gap-4  whitespace-nowrap">
          <NavLink to={`${WEB_URI}/user/login`}>
            <button className="p-2 py-1 rounded-lg bg-white-500 h-fit  hover:bg-green-100 border text-green-500 font-bold   ">
              Login
            </button>
          </NavLink>
          <NavLink
            to={`${WEB_URI}/welcomeezer`}
            className="p-2 rounded-lg py-1 bg-white-500 border  hover:bg-green-100  text-green-500  font-bold  "
          >
            <button className="">Sign up</button>
          </NavLink>
        </div>

        <NavLink
          to={`${WEB_URI}/welcomeezer`}
          className="text-white bg-green-500 hover:text-green-500 hover:bg-white border border-green-500 rounded-lg  p-5 shadow-md font-medium flex items-center h-10"
        >
          <div className="flex items-center justify-center gap-1 whitespace-nowrap">
            <HiOutlineCheckBadge size={20} />
            <button className="font-bold"> Get Started</button>
          </div>
        </NavLink>
      </div>

      <div
        className={`md:hidden p-3 ${
          toggleBar ? "flex" : "hidden"
        } flex-col justify-between gap-2 flex-wrap w-[97%] fixed z-[99] top-24  h-md bg-[#f2fbf0] transition-all duration-[1000ms] ease-in-out rounded-md rounded-tr-3xl rounded-tl-3xl rounded-bl-3xl rounded-br-3xl`}
      >
        <NavLink
          to={`${WEB_URI}`}
          className="text-black font-medium hover:text-green-800 hover:transition-all hover:ease-in-out my-2 flex gap-1 justify-center"
        >
          <button className="text-2xl">Home</button>
        </NavLink>
        <NavLink
          to={`${WEB_URI}`}
          className="text-black font-medium hover:text-green-800 hover:transition-all hover:ease-in-out my-2 flex gap-1 justify-center"
        >
          <button className="text-2xl">Explore</button>
        </NavLink>
        <NavLink
          to={`${WEB_URI}/brands`}
          className={`text-black font-medium hover:text-green-800 hover:transition-all hover:ease-in-out my-2 flex gap-1 justify-center text-2xl`}
        >
          <span className={` ${Style.navNames}  `}>For Brands</span>
        </NavLink>
        <NavLink
          to={`${WEB_URI}/creators`}
          className={`text-black font-medium hover:text-green-800 hover:transition-all hover:ease-in-out my-2 flex gap-1 justify-center text-2xl`}
        >
          <span className={` ${Style.navNames}  `}>For Creators</span>
        </NavLink>

        <NavLink
          to={`${WEB_URI}/welcomeezer`}
          className="text-black font-medium hover:text-green-800 hover:transition-all hover:ease-in-out my-2 flex gap-1 justify-center"
        >
          <button className="text-2xl">Register</button>
        </NavLink>

        <NavLink
          to={`${WEB_URI}/user/login`}
          className="text-black font-medium hover:text-green-800 hover:transition-all hover:ease-in-out my-2 flex gap-1 justify-center"
        >
          <button className="text-2xl">Login</button>
        </NavLink>

        <NavLink
          to={`${WEB_URI}/welcomeezer`}
          className=" bg-green-500 text-white hover:text-black hover:bg-green-500 rounded-lg shadow-gree-500 xl:px-3 shadow-md font-small flex items-center h-14 gap-2"
        >
          <div className="flex items-center justify-center gap-2">
            <HiOutlineCheckBadge className="pl-3 ml-14" size={50} />
            <button className=" ml-8 text-xl justify-center">
              {" "}
              Get Started
            </button>
          </div>
        </NavLink>
      </div>
    </nav>
  );
}

export default Navbar;

import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import "../../App.css";
import { BiCategoryAlt } from "react-icons/bi";
import { CiHashtag } from "react-icons/ci";
import { GrLocation } from "react-icons/gr";
import { FaCalendarAlt } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";

import { MAIN_URI, WEB_URI } from "../../services/helper";
import axios from "axios";
import Loading from "../../components/Loading";
import Navbar from "../../components/NewNav";
import { Helmet } from "react-helmet-async";
import ApplyCouponComponent from "./ApplyCouponComponent";
const CheckoutUserDetails = lazy(() =>
  import("../../components/CheckoutUserDetails")
);
const PurchasedModal = lazy(() => import("../../components/PurchasedModal"));
const DiscussionSection = lazy(() =>
  import("../../components/DiscussionSection")
);

const workshopDefaultImg =
  "https://firebasestorage.googleapis.com/v0/b/ez-the-one-app.appspot.com/o/assets%2Fworkshopdefaultphoto.avif?alt=media&token=bbf9ce29-1cb5-4271-a3ac-b48b178a176f";
const CatalogHomepage = () => {
  const [catalog, setCatalog] = useState(null);
  const [isFetchingCatalog, setIsFetchingCatalog] = useState(true);
  const { catalogID } = useParams();
  const [isPurchased, setIsPurchased] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  useEffect(() => {
    const fetchCatalog = async () => {
      try {
        const res = await axios.get(`${MAIN_URI}/seller/catalog/${catalogID}`);
        const catalogData = res.data.catalog;
        setCatalog(catalogData);
        console.log(catalogData);
        setIsFetchingCatalog(false);
      } catch (err) {
        setIsFetchingCatalog(true);
      }
    };

    fetchCatalog();
  }, [catalogID]);

  if (isFetchingCatalog) return <Loading />;

  if (!catalog) return <Loading />; // Handle case where catalog is null or undefined

  return (
    <Suspense fallback={<Loading />}>
      <Helmet>
        <title>{catalog?.itemTitle}</title>
        <meta name="description" content={catalog?.description.slice(0, 20)} />
        <meta property="og:title" content={catalog?.itemTitle} />
        <meta
          property="og:description"
          content={catalog?.description.slice(0, 20)}
        />
        <meta
          property="og:image"
          content={catalog?.thumbnail[0]?.url || workshopDefaultImg}
        />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="w-full sticky top-0  z-50 shadow h-[10vh] bg-white">
        <Navbar />
      </div>
      {isPurchased && <PurchasedModal to={`/attendeeslist/${catalogID}`} />}

      {openDetail && !isPurchased && (
        <CheckoutUserDetails
          price={`${catalog?.price?.amount}`}
          openDetail={openDetail}
          setOpenDetail={setOpenDetail}
          purchaseAmount={Math.round(
            catalog?.price?.amount + (catalog?.price?.amount * 7) / 100
          )}
          questions={catalog?.questionForAttendee?.question}
          catalog={catalog}
          setIsPurchased={setIsPurchased}
          paymentType={catalog?.paymentType}
        />
      )}
      <div className="px-2 md:mx-auto pt-12 bg-[#f4f5f7] md:px-24 relative">
        <div className="flex max-w-5xl flex-col lg:flex-row m-auto gap-4 lg:gap-10 p-4">
          <div className="lg:w-2/5">
            <img
              src={catalog?.thumbnail[0]?.url || workshopDefaultImg}
              alt="product-"
              className="w-full rounded-lg aspect-square object-cover shadow-md shadow-gray-400"
              loading="lazy"
              rel="preload"
            />

            <div className="mt-4">
              <p className="border-b font-[500] border-gray-300 pb-1 mb-3">
                Hosted by
              </p>
              <div className="flex items-center gap-3">
                <img
                  src={catalog?.seller_avatar}
                  alt="hoster "
                  className="w-12 rounded-full bg-white"
                />
                <Link
                  to={`${WEB_URI}/${catalog?.username}`}
                  className="text-blue-600 ml-1"
                >
                  @{catalog?.username}
                </Link>
              </div>
            </div>
            {/* <div className="mt-4">
              <Link
                to={`/attendeeslist/${catalogID}`}
                className="border-b font-[500] border-gray-300 pb-1 mb-3"
              >
                Guests
              </Link>
            </div> */}
          </div>

          <div className="lg:w-3/5">
            <div className="mt-5 sm:mt-0">
              <ShareBanner />
              <h2 className="text-2xl md:text-5xl font-bold text-green-900">
                {catalog?.itemTitle}
              </h2>
              <p className="mt-4 flex items-center gap-2 ">
                <FaCalendarAlt />
                <span>
                  {catalog?.workShopDetails?.date?.slice(0, 6)} |{" "}
                  {catalog?.workShopDetails?.time}{" "}
                </span>
                -
                <span>
                  {catalog?.workShopDetails?.date !==
                    catalog?.workShopDetails?.endDate && (
                    <>{catalog?.workShopDetails?.endDate?.slice(0, 6)} | </>
                  )}
                  {catalog?.workShopDetails?.endTime}{" "}
                </span>
              </p>
              <p className="flex items-center gap-3 mt-2">
                <BiCategoryAlt />
                {catalog?.category}
              </p>
              {catalog?.locationOfEvent && (
                <p className="flex items-center my-6 gap-2 text-sm sm:text-xl">
                  <GrLocation size={20} />
                  {catalog?.locationOfEvent}
                </p>
              )}
              <div className="flex mt-4 flex-wrap gap-4">
                {catalog?.tags?.map((element, idx) => (
                  <p
                    key={idx}
                    className="flex items-center gap-1 bg-black/70 text-white py-1 px-2 rounded-lg text-sm font-[500]"
                  >
                    <CiHashtag />
                    {element?.tag}
                  </p>
                ))}
              </div>
            </div>

            {/* ------------------ Payment Summary-------------------- */}
            <div className="my-6 rounded-xl border p-1 bg-zinc-50 shadow-md">
              <div className="px-4 py-2 bg-zinc-200 rounded-t-xl flex justify-between">
                <p className="font-[500]">
                  {catalog?.totalSold >=
                  catalog?.workShopDetails?.noOfParticipants
                    ? "Registration Closed"
                    : "Welcome! To join the event, please register below."}
                </p>
              </div>

              <div className="p-4">
                {catalog?.totalSold >=
                catalog?.workShopDetails?.noOfParticipants ? (
                  <p className="text-zinc-500 my-3 font-[500]">
                    This event is not currently taking registrations. You may
                    contact the host or subscribe to receive updates.
                  </p>
                ) : (
                  <div>
                    {catalog?.paymentType !== "Free" && (
                      <div className="flex my-3 justify-between">
                        <p className="text-lg font-bold">Total</p>
                        <div className="">
                          <p className="mb-1 text-lg font-bold flex items-center">
                            <span>{catalog?.price?.currencyCode || "₹"}</span>

                            <span className="pl-2 text-gray-700">
                              {Math.round(
                                catalog?.price?.amount +
                                  (catalog?.price?.amount * 7) / 100
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {catalog?.totalSold >=
                catalog?.workShopDetails?.noOfParticipants ? (
                  <p className="bg-rose-600 cursor-not-allowed text-white text-center font-medium px-4 py-2 w-full rounded  hover:opacity-90 transition-opacity">
                    Sold Out
                  </p>
                ) : (
                  <button
                    disabled={openDetail}
                    onClick={() => setOpenDetail(true)}
                    className={`${
                      openDetail
                        ? "bg-blue-600 cursor-not-allowed"
                        : "bg-green-500 cursor-pointer"
                    } h-[40px] text-center mt-2 py-1.5 w-full flex items-center justify-center rounded-md font-medium text-blue-50 hover:bg-green-600`}
                  >
                    {catalog?.paymentType === "Free"
                      ? "Register"
                      : " Proceed to Pay"}
                  </button>
                )}
              </div>
            </div>
            {catalog?.totalSold >=
            catalog?.workShopDetails?.noOfParticipants ? (
              ""
            ) : (
              <ApplyCouponComponent catalog={catalog} setCatalog={setCatalog} />
            )}

            {/* ------------------------------------------------------------------------------------------- */}
            <h4 className="mt-6 font-[500] mb-2 text-xl border-b border-gray-300 pb-1 ">
              {" "}
              About Event
            </h4>
            <div className="mb-6 text-xs md:text-[16px] md:leading-6 text-gray-700">
              <ProductDescription description={catalog?.description} />
            </div>

            <h4 className="mt-6 font-[500] mb-2 text-xl border-b border-gray-300 pb-1 ">
              {" "}
              Location
            </h4>
            <div>
              <iframe
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBiD4MqYxG4jILj-DGHyKIs4ubBrs_5okQ&q=${formatAddressForQuery(
                  catalog?.locationOfEvent
                )}`}
                className="w-full md:m-4 shadow-md aspect-video rounded-xl"
              ></iframe>
            </div>

            {/* -------------------------Discussion------------------------- */}
            <div className="mt-5 md:mt-0 my-16 md:mb-0">
              <DiscussionSection catalog={catalog} />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default CatalogHomepage;

const ProductDescription = ({ description }) => {
  return <div dangerouslySetInnerHTML={{ __html: description }} />;
};
function formatAddressForQuery(address) {
  // Replace spaces with '+' and leave commas intact
  return address?.replace(/\s/g, "+");
}
export function ShareBanner() {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000); // Reset success message after 3 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <div className="relative isolate flex items-center border gap-x-6 overflow-hidden rounded-2xl bg-gray-50 px-6 py-2 mb-4 sm:py-1 sm:px-3.5">
      <div className="flex relative flex-wrap justify-center sm:justify-around w-full sm:px-4 items-center gap-x-4 gap-y-2">
        <p className="text-sm mr-auto text-gray-900">
          <strong className="font-semibold">Share it on social media</strong>
        </p>
        <button
          className="flex gap-2 items-center rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          onClick={copyToClipboard}
        >
          {copySuccess ? "Link Copied !" : "Copy Share Link"}
          {copySuccess ? "" : <FiCopy />}
        </button>
      </div>
    </div>
  );
}

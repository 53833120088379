import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function EventFull() {
  return (
    <div className="h-screen w-screen bg-neutral-300 -z-10">
    <div
      className="px-2 md:px-0 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      style={{ zIndex: 999 }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <div className="flex items-center justify-center mb-6">
          <FaCheckCircle className="text-green-500 text-6xl" />
        </div>
        <h1 className="text-3xl font-bold mb-4 text-center">
          Attention!
        </h1>
        <p className="text-gray-600 mb-6 text-center">
         Event is already full at it's max capacity so we cannot proceed with your booking
        </p>
        <div className="flex justify-center">
          <Link
            to={"https://ezage.in"}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
          >
            Thank You
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}

import React, { useEffect, useState } from "react";

import Navbar from "../../components/NewNav";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MAIN_URI, WEB_URI } from "../../services/helper";
import { BiArrowToLeft } from "react-icons/bi";

import { BsPeople } from "react-icons/bs";

import toast from "react-hot-toast";
const formattedBookingsDate = (dateString) => {
  var date = new Date(dateString);

  var day = String(date.getDate()).padStart(2, "0");
  var month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero based
  var year = date.getFullYear();

  return day + "/" + month + "/" + year;
};
export default function Attendeeslist() {
  const { catalogID } = useParams();
  const [bookings, setBookings] = useState([]);
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const [totalRevenue, setTotalRevenue] = useState(0);
  useEffect(() => {
    if (catalogID) {
      axios
        .get(`${MAIN_URI}/get-all-bookings/${catalogID}`)
        .then((res) => {
          setBookings(res.data.bookings);
          const totalAmount = res.data.bookings?.reduce((total, item) => {
            return total + item.price.amount;
          }, 0);
          setTotalRevenue(totalAmount);
          setQuestions(res.data.questions);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error, we are working on it!");
        });
    }
  }, []);

  // ---------------------------------------------------------------

  return (
    <div>
      <div className="w-full sticky top-0  z-50 shadow h-[10vh] bg-white">
        <Navbar />
      </div>
      <div className="bg-neutral-100 h-auto min-h-[500px] pb-4 ">
        <div className=" text-xl md:text-3xl flex flex-col md:flex-row md:items-center gap-3 font-[500] md:font-bold p-4 mb-2 bg-neutral-200 ">
          <h2 className="flex items-center gap-2">
            <BiArrowToLeft
              onClick={() => navigate(-1)}
              className=" cursor-pointer "
            />{" "}
            {bookings[0]?.seller_itemTitle || "Bookings"}
          </h2>
          <div className="flex md:items-center flex-col md:flex-row  gap-3 md:ml-auto">
            <span className="block text-sm md:text-base font-[500] text-green-600 border-black bg-white border px-4 py-1  lg:mr-6 rounded-xl">
              <h2> Total Guests</h2>

              <p className="flex items-center gap-2 text-black">
                {" "}
                <BsPeople /> {bookings?.length}
              </p>
            </span>
          </div>
        </div>

        <div className="md:p-4 pt-0 text-center ">
          <div className="relative overflow-x-auto rounded-xl text-center">
            <table className="w-full text-sm  rtl:text-right text-gray-500 text-center ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Date on booked
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Profile
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookings?.map((booking, idx) => (
                  <tr
                    key={idx}
                    className="bg-white whitespace-nowrap border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {formattedBookingsDate(booking.createdAt)}
                    </th>
                    <td className="px-6 py-4">
                      {booking.buyer_username ? (
                        <Link
                          target="_blank"
                          to={
                            booking.buyer_username
                              ? `${WEB_URI}/${booking.buyer_username}`
                              : "#"
                          }
                          className={`${
                            booking.buyer_username
                              ? "cursor-pointer"
                              : "cursor-default"
                          } flex flex-row items-center col-span-2`}
                        >
                          <div className="w-[40px] h-[40px] lg:me-2">
                            <img
                              src={booking.buyer_photo || ""}
                              alt=""
                              className="rounded-full"
                            />
                          </div>
                          <div className="">
                            <p className="text-black font-[500] ">
                              {booking.buyer_name}
                            </p>{" "}
                            <p className="font-normal text-gray-400">
                              @{booking.buyer_username}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        <p className="text-black font-[500] ">
                          {booking.buyer_name}
                        </p>
                      )}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {bookings.length === 0 && (
            <div className="mt-10 w-full flex items-center justify-center">
              <p className="font-extrabold text-2xl">No bookings yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const isProd = true;

const WEB_URI = isProd ? 'https://ezage.in' : 'http://localhost:3000';
const AUTH_URI = isProd
	? 'https://backend.ezage.in/auth'
	: 'http://localhost:5000/auth';
const BACKEND_URI = isProd
	? 'https://backend.ezage.in'
	: 'http://localhost:5000';
const MAIN_URI = isProd
	? 'https://backend.ezage.in/api/v1'
	: 'http://localhost:5000/api/v1';
const PRODUCT_URI = isProd? 'https://products.ezage.in':'http://localhost:4000'
const INFLUENCERS_URL = isProd? 'https://influencers.ezage.in':'http://localhost:8000'
export { WEB_URI, BACKEND_URI, AUTH_URI, MAIN_URI,PRODUCT_URI ,INFLUENCERS_URL};

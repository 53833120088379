"use client";

import { FaArrowRight } from "react-icons/fa";
import { ParallaxText } from "../src/components/ParallaxText";
import { CgGames } from "react-icons/cg";
import { IoLogoReact } from "react-icons/io5";
import { SiTailwindcss } from "react-icons/si";
import { TbBrandFramerMotion } from "react-icons/tb";
import TopGames from "../src/components/TopGames";
import Navbar from "./components/NewNav";
import { BiRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div>
      <div className="w-full sticky top-0  z-50 shadow h-[10vh] bg-white">
        <Navbar />
      </div>
      <header
        className="px-4 pt-6 pb-8 lg:px-18 w-full m-auto lg:pb-18"
        style={{
          background: "linear-gradient(131deg, #E8E5FE 26.82%, #FFEED8 96.89%)",
        }}
      >
        <div className=" max-w-7xl m-auto w-full">
          <div className="bg-white rounded-2xl pt-6  px-4 lg:px-10 pb-4 lg:pb-8">
            <div className="flex flex-wrap -m-4 mb-8">
              <div className="w-full md:w-2/3 p-4 md:p-4">
                <div className="h-full overflow-hidden border-2 border-neutral-200 border-opacity-50 rounded-3xl py-8 lg:py-32 lg:pb-8 pl-4 lg:pl-18 pr-4 lg:pr-16">
                  <h1 className="font-heading mb-12 font-semibold text-4xl md:text-6xl  max-w-7xl">
                    <span className="relative z-10">
                      Your One-Stop Shop for
                    </span>
                    <span className="bg-[#ffd9cd] rounded-lg px-4 inline-block">
                      Ezage Services.
                    </span>
                  </h1>
                  <h2 className="font-heading text-neutral-600 flex gap-4 items-center tracking-tight text-2xl font-semibold mb-24">
                    Secure Payment, Fast Delivery{" "}
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/bharat-play-e624c.appspot.com/o/assets%2Fgame%20(1).png?alt=media&token=a7313872-6778-4ef1-8f2e-ceecf3d9c2a0"
                      className="w-12"
                    />
                  </h2>
                  <div>
                    <Link
                      className="flex gap-4 text-white w-fit bg-black rounded-lg px-5 py-2 items-center justify-center"
                     to={'/6672a1a91ed54460dcdcfe42'}
                    >
                      Explore Services <BiRightArrow size={25} />
                    </Link>
                  </div>
                  <div className=" mt-12 landingpage-scroll-section flex whitespace-nowrap  text-gray-400">
                    <ParallaxText baseVelocity={-3}>
                      <span className="flex items-center gap-2 mx-4">Ezage</span>
                      <span className="flex items-center gap-2 mx-4">Ezage</span>
                      <span className="flex items-center gap-2 mx-4">Ezage</span>
                      <span className="flex items-center gap-2 mx-4">Ezage</span>
                    </ParallaxText>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 p-4">
                <div className="relative  h-full">
                  {/* <img
                  className="absolute inset-0 rounded-3xl w-full h-full object-cover"
                  src="https://static.shuffle.dev/components/preview/5e80167f-76b7-4ee3-bb5e-b8126519dee3/assets/public/mirga-assets/images/hero/background1.png"
                  alt=""
                /> */}
                  <TopGames />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -m-2">
              <div className="w-full md:w-1/3 p-2 ">
                <div className="rounded-4xl p-8 bg-[#ffd9cd] rounded-3xl  h-full relative overflow-hidden">
                  <div className="flex flex-col items-start justify-between gap-6 h-full relative z-20">
                    <h2 className="font-heading text-3xl tracking-tight font-semibold max-w-xs">
                      Popular Services 
                    </h2>
                    <Link
                      className="group inline-flex justify-center items-center text-center tracking-tight gap-3"
                      to={'/6672a1a91ed54460dcdcfe42'}
                    >
                      <span className="text-neutral-600 tracking-tight font-semibold group-hover:text-neutral-800 transition duration-200">
                        Learn more now
                      </span>
                      <div className="w-6 h-6 bg-white rounded-full border border-neutral-50 group-hover:border-neutral-200 transition duration-200 flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 1L5.2825 1.6965L9.086 5.5H1V6.5H9.086L5.293 10.293L6 11L11 6L6 1Z"
                            fill="#19191B"
                          ></path>
                        </svg>
                      </div>
                    </Link>
                  </div>
                  <img
                    className="absolute h-32 sm:h-52 object-cover bottom-0 right-0 z-10"
                    src="https://static.shuffle.dev/components/preview/5e80167f-76b7-4ee3-bb5e-b8126519dee3/assets/public/mirga-assets/images/hero/woman-picture1.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/3 p-2">
                <div class="rounded-3xl border-2 border-neutral-200 border-opacity-50 h-full p-8">
                  <div class="flex items-center justify-between flex-wrap -m-2">
                    <div class="w-full xl:w-1/2 p-2">
                      <p class="text-neutral-600 tracking-tight text-lg font-medium max-w-xs">
                        Upto
                      </p>
                      <h2 class="font-heading text-amber-500 tracking-tight text-3xl font-semibold mb-4">
                        88% off
                      </h2>
                      <p class="text-neutral-600 tracking-tight text-lg font-medium max-w-xs">
                        on your next service purchase!
                      </p>
                    </div>
                    <div class="w-full xl:w-1/2 p-2">
                      <img
                        class="mx-auto h-40"
                        src="https://static.shuffle.dev/components/preview/5e80167f-76b7-4ee3-bb5e-b8126519dee3/assets/public/mirga-assets/images/hero/circle.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 p-2">
                <div className="p-8 h-full">
                  <h2 className="font-heading text-5xl tracking-tight font-semibold mb-7 max-w-xs">
                 Purchase  services you want securely
                  </h2>
                  <Link
                    className="group inline-flex justify-center items-center text-center tracking-tight gap-3"
                    to={'/6672a1a91ed54460dcdcfe42'}
                  >
                    <span className="text-neutral-600 tracking-tight font-semibold group-hover:text-neutral-800 transition duration-200">
                      Explore more
                    </span>
                    <div className="w-6 h-6 bg-white rounded-full border border-neutral-50 group-hover:border-neutral-200 transition duration-200 flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6 1L5.2825 1.6965L9.086 5.5H1V6.5H9.086L5.293 10.293L6 11L11 6L6 1Z"
                          fill="#19191B"
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </header>{" "}

      <footer></footer>
    </div>
  );
};

export default Header;

import React from "react";

import logo from "../assets/ez circle.png";
const Loading = () => {
  return (
    <section className="bg-green-900 z-[9999] relative place-items-center grid h-screen w-screen gap-4">
      {/* <!--   ITEM 1 --> */}
      <div className="bg-green-500 w-48 h-48  absolute animate-ping rounded-full delay-5s shadow-xl"></div>
      {/* <!--   ITEM 2 --> */}
      <div className="bg-green-400 w-32 h-32 absolute animate-ping rounded-full shadow-xl"></div>
      {/* <!--   ITEM 3 --> */}
      <div className="bg-white w-24 h-24 absolute animate-pulse rounded-full shadow-xl grid place-content-center">
        <img
          src={logo}
          alt="logo"
          className="text-green-900 opacity-100  h-24 w-24"
        />
      </div>
      {/* <!--   SVG LOGO --> */}
    </section>
  );
};

export default Loading;

"use client";
import React from "react";
import { Link } from "react-router-dom";

export default function TopGames() {
  return (
    <section className=" rounded-3xl bg-gradient-to-br from-zinc-700 via-neutral-700 to-black px-8 py-6 h-full w-full text-white">
      <h1 className="text-xl font-bold bold-font ">POPULAR SERVICES </h1>
      <div className="grid gap-3 mt-4">
        <Link
          to="/66968d7a16e044fc41e0e3eb"
          className="border rounded-xl flex gap-4 p-2 bg-white text-black font-[500]"
        >
          <img
            src={
              "https://res.cloudinary.com/ds6spmr71/image/upload/v1720594355/oogwx8chv2onfrajndl5.gif"
            }
            width={64}
            height={64}
            alt="bgmi logo"
            className=" rounded-lg aspect-square object-cover"
          />

          <p className="grid text-gray-400">
            {" "}
            <span className=" text-black">limit and coupon testing</span> 
            Sold Out
          </p>
        </Link>
        <Link
          to="/666c4122c87d02609583f3ff"
          className="border rounded-xl flex gap-4 p-2 bg-white text-black font-[500]"
        >
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/ez-the-one-app.appspot.com/o/assets%2Fworkshopdefaultphoto.avif?alt=media&token=bbf9ce29-1cb5-4271-a3ac-b48b178a176f"
            }
            width={64}
            height={64}
            alt="bgmi logo"
            className=" rounded-lg"
          />

          <p className="grid text-gray-400">
            {" "}
            <span className=" text-black">Ted Talk</span> 
            Rs.100
          </p>
        </Link>
        <Link
          to="/668e2fb4318db8ed6896f65c"
          className="border rounded-xl flex gap-4 p-2 bg-white text-black font-[500]"
        >
          <img
            src={
              "https://res.cloudinary.com/ds6spmr71/image/upload/v1720594355/oogwx8chv2onfrajndl5.gif"
            }
            width={64}
            height={64}
            alt="bgmi logo"
            className=" rounded-lg aspect-square object-cover"
          />

          <p className="grid text-gray-400">
            {" "}
            <span className=" text-black">testing</span> 
          Free Registration
          </p>
        </Link>
        <Link
          to="/6672a1a91ed54460dcdcfe42"
          className="border rounded-xl flex gap-4 p-2 bg-white text-black font-[500]"
        >
          <img
            src={
              "https://res.cloudinary.com/ds6spmr71/image/upload/v1718788521/ruqa9ppye2emi1bqwnrp.jpg"
            }
            width={64}
            height={64}
            alt="bgmi logo"
            className=" rounded-lg aspect-square object-cover"
          />

          <p className="grid text-gray-400">
            {" "}
            <span className=" text-black">Bengal Entrepreneurs Meet</span> 
            Rs.600
          </p>
        </Link>
     
      
      </div>
    </section>
  );
}

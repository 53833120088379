import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";

const ApplyCouponComponent = ({ catalog, setCatalog }) => {
  const [inputCode, setInputCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponApplyOpen, setCouponApplyOpen] = useState(false);

  const handleApplyCoupon = () => {
    const coupon = catalog.coupons.find((c) => c.code === inputCode);
    if (coupon) {
      const discountAmount =
        (catalog.price.amount * coupon.discountPercentage) / 100;
      const updatedCatalog = {
        ...catalog,
        price: {
          ...catalog.price,
          amount: catalog.price.amount - discountAmount,
        },
      };

      setCatalog(updatedCatalog);
      setDiscount(coupon.discountPercentage);
      toast.success(
        `Coupon applied! You get a ${coupon.discountPercentage}% discount.`
      );
    } else {
      toast.error("Invalid coupon code.");
    }
  };

  return (
    <div className="">
      <Toaster />
     {discount == 0 && <button
        onClick={() => setCouponApplyOpen(!couponApplyOpen)}
        className="rounded-2xl mb-4 border-2 border-dashed border-black bg-white px-6 py-3 font-semibold uppercase text-black transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-md hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none"
      >
        Have a Coupon to apply?
      </button>}

      {discount > 0 ? (
        <div className="mt-4 p-4 bg-green-100 text-green-800 rounded-md">
          You have a {discount}% discount applied!
        </div>
      ) : (
        couponApplyOpen && (
          <div className="space-y-4 p-6 bg-white rounded-lg shadow-md">
            <div className="form-group">
              <label
                htmlFor="inputCode"
                className="block text-sm font-medium text-gray-700"
              >
                Coupon Code:
              </label>
              <input
                type="text"
                id="inputCode"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <button
              onClick={handleApplyCoupon}
              className="w-full bg-black text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600"
            >
              Apply Coupon
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default ApplyCouponComponent;

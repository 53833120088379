import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CatalogHomepage from "./pages/catalog/CatalogHomepage";
import OrderSuccess from "./pages/OrderSuccess";
import EventFull from "./pages/EventFull";
import Attendeeslist from "./pages/catalog/Attendeeslist";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/order-success",
    element: <OrderSuccess />,
  },
  {
    path: "/event-full",
    element: <EventFull />,
  },
  {
    path: "/:catalogID",
    element: <CatalogHomepage />,
  },
  {
    path: "/attendeeslist/:catalogID",
    element: <Attendeeslist />,
  },
]);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);
